import './../less/spinner.less';
import './../less/EventDropdown.less';

import "bootstrap";
import './../less/bootstrap.less';
import "jquery";
import "jquery-ui";
import "jquery.easing";
import "jquery-validation";
import "tablesorter";

import * as $ from "jquery";
window["jQuery"] = $;
window["$"] = $;

export function myAppTestFunction():string {
    console.log('myAppTestFunction');
    return 'myjunk';
}
export function myAppTestFunctionWithParam(param:string) {
    console.log(`myAppTestFunction with param ${param}`);
}
